// Set your brand colors
$mgg-grey: #8c9b9d;
$mgg-grey-light: #a9afb7;
$mgg-grey-lighter: #dee2e5;
$mgg-orange: #e67e22;
$mgg-yellow: #f1b70e;
$mgg-green: #2ecc71;
$mgg-turquoise: #1abc9c;
$mgg-blue: #3498db;
$mgg-purple: #8e44ad;
$mgg-red: #e74c3c;
$mgg-white-ter: #ecf0f1;
$mgg-primary: #34495e !default;
$mgg-yellow-invert: #fff;
$mgg-text: $mgg-primary;
$mgg-link: $mgg-turquoise;
$mgg-button-color: $mgg-primary;
$mgg-navbar-dropdown-background-color: $mgg-white-ter;
$mgg-navbar-divider-background-color: $mgg-grey-lighter;

// Path to Bulma's sass folder
@use "bulma/sass" with (
    $family-primary: '"Nunito", sans-serif',
    $primary: $mgg-primary,
    $link: $mgg-link,
    $danger: $mgg-red,
    $warning: $mgg-yellow,
    $success: $mgg-green,
    $control-border-width: 2px,
    $input-shadow: none,
    $navbar-background-color: $mgg-primary,
    $navbar-height: 4rem,
  );

$radius-small: 3px;
$radius: 0.4em;
$radius-large: 8px;

$size-7: 0.85em;
$title-weight: 500;
$subtitle-weight: 400;
$subtitle-color: darken($mgg-grey, 20);

$border-width: 2px;

$body-size: 15px;
$footer-background-color: $mgg-white-ter;

$text-light: lighten($mgg-primary, 10);
$text-strong: darken($mgg-primary, 5);

$box-color: $mgg-text;
$box-background-color: $mgg-white-ter;
$box-shadow: none;

$link-hover: darken($mgg-link, 10);
$link-focus: darken($mgg-link, 10);
$link-active: darken($mgg-link, 10);

$button-hover-color: darken($mgg-primary, 5); // primary-dark
$button-focus: darken($mgg-primary, 5); // primary-dark
$button-active-color: darken($mgg-primary, 5); // primary-dark

$navbar-background-color: $mgg-primary;
$navbar-item-color: $mgg-white-ter;
$navbar-item-hover-color: $mgg-link;
$navbar-item-hover-background-color: transparent;
$navbar-item-active-color: $mgg-link;
$navbar-dropdown-arrow: #fff;
$navbar-dropdown-border-top: 1px solid $mgg-navbar-divider-background-color;
$navbar-dropdown-item-hover-color: $mgg-link;
$navbar-dropdown-item-hover-background-color: transparent;
$navbar-dropdown-item-active-background-color: transparent;
$navbar-dropdown-item-active-color: $mgg-link;
    
$control-height: 2.5em;

// Import the Google Font
@import url("https://fonts.googleapis.com/css?family=Nunito:400;700");
