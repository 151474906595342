#root.div {
  height: 100%
}

.is-fullheight-100vh {
  min-height: 100vh;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.has-wrap-anywhere {
  overflow-wrap: anywhere;
}

.no-wrap {
  white-space: nowrap;
}

.has-background-transparent {
  background-color: transparent !important;
}

.has-border-bottom {
  border-bottom: 1px solid #dbdbdb;
}

.text-box-width-short {
  width: 75px !important;
}

.bottom-expanded-menu {
  bottom: 80px;
  left: calc(100% - 130px);
  position: fixed;
  width:125px;
  max-width: 100px;
  z-index: 100;
}

#groupNameHeader {
  border-bottom: solid 2px #34495e;
}

.is-div-rounded {
  border-radius: 9999px;
  padding-top: calc(50% - 25px);
}

.hero.is-extra-small .hero-body {
  padding: 1rem;
}

.rotate {
  animation: rotation 5s infinite linear;
}

@keyframes rotation {
  from {
      transform: rotate(0deg);
  }

  to {
      transform: rotate(359deg);
  }
}

.lds-dual-ring {
  
  width: 20px;
  height: 20px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid #000;
  border-color: #000 transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
